import styles from './index.module.scss';
import React, { useEffect, useRef, useState } from 'react';
// @ts-ignore
import i18n from '@/i18n';
import dynamic from 'next/dynamic';
import { useThrottleFn } from 'ahooks';
import Router from 'next/router'
import { CarouselProps, ButtonProps } from '@mitra/sg-design';
import { useRouter } from 'next/router';
import { getStaticPath } from '@/utils/staticPath'

const { useTranslation } = i18n;

const Carousel = dynamic<CarouselProps>(
  () => import('@mitra/sg-design').then((mod) => mod.Carousel),
  { ssr: false }
);
const Button = dynamic<ButtonProps>(
  () => import('@mitra/sg-design').then((mod) => mod.Button),
  { ssr: false }
);

export default function Homepage() {
  const router = useRouter();
  const [swapAuto, setSwapAuto] = useState<boolean>(false)
  const [userScrollY, setUserScrollY] = useState<number>();
  const { run } = useThrottleFn(
    () => {
      setUserScrollY(window.scrollY);
    },
    { wait: 500 },
  );
  const [swapList, setSwapList] = useState([{
    src: getStaticPath('/images/benefitsSwitch1.png'),
    id: '1',
  },
  {
    src: getStaticPath('/images/benefitsSwitch2.png'),
    id: '2',
  },
  {
    src: getStaticPath('/images/benefitsSwitch3.png'),
    id: '3',
  },
  {
    src: getStaticPath('/images/benefitsSwitch4.png'),
    id: '4',
  }]);
  const [activeSwap, setActiveSwap] = useState<string>('1');
  useEffect(() => {
    // report({
    //   page_type: 'apc_wsebsite_homepage',
    //   operation: 'view',
    // });
    window.addEventListener('scroll', run);
    // return () => window.removeEventListener('scroll', () => { run() })
  }, []);
  useEffect(() => {
    if (userScrollY >= 1230) {
      setSwapAuto(true)
      window.removeEventListener('scroll', run)
    }
  }, [userScrollY])

  const { t } = useTranslation();
  return (
    <div className={styles.benefits}>
      <div className={styles.carouselWrap}>
        <div className={styles.carousel}>
          <img
            className={styles.benefitsLace}
            src={getStaticPath('/images/benefitsLace.png')}
            alt=""
          />
          <div className={styles.overLap}>
            <div className={styles.overLapLeft}>
              <h1 className={styles.overLapTitle}>
                {t('pw_ph_banner_content_1')} <br />
                {t('pw_ph_banner_content_2')}
              </h1>
              <p className={styles.overLapDesc}>
                {t('pw_ph_banner_content_3')}
              </p>
              <div className={styles.IconGroup}>
                <div className={styles.IconGroupLeft}>
                  <a target="_blank" rel="noopener noreferrer" href="https://info.dailyshopee.vn/"><img src={getStaticPath("/images/AppleIcon.svg")} alt="" /></a>
                  <a target="_blank" rel="noopener noreferrer" href="https://info.dailyshopee.vn/"><img src={getStaticPath("/images/GoogleIcon.svg")} alt="" /></a>
                </div>
                <div className={styles.IconGroupRight}>
                  <img src={getStaticPath("/images/QRCode.png")} alt="" />
                  <p>Scan QR to download</p>
                </div>
              </div>
            </div>

            <div className={styles.overLapRight}>
              <img src={getStaticPath("/images/phoneView.png")} alt="" />
            </div>
          </div>
        </div>
      </div>

      <div className={styles.Main}>
        <div className={styles.MainBenefits}>
          <h1>{t('pw_ph_benefits')}</h1>
          <div className={styles.IconBoxLine}>
            <div className={styles.IconBox}>
              <img src={getStaticPath("/images/ExtraIncome.png")} alt="" />
              <p>{t('pw_ph_benefits_1')}</p>
              <p>{t('pw_ph_benefits_description_1')}</p>
            </div>
            <div className={styles.IconBox}>
              <img src={getStaticPath("/images/ComptPrices.png")} alt="" />
              <p>{t('pw_ph_benefits_2')}</p>
              <p>{t('pw_ph_benefits_description_2')}</p>
            </div>
            <div className={styles.IconBox}>
              <img src={getStaticPath("/images/EasyConvenient.png")} alt="" />
              <p>{t('pw_ph_benefits_3')}</p>
              <p>{t('pw_ph_benefits_description_3')}</p>
            </div>
            <div className={styles.IconBox}>
              <img src={getStaticPath("/images/WaivedFees.png")} alt="" />
              <p>{t('pw_ph_benefits_4')}</p>
              <p>{t('pw_ph_benefits_description_4')}</p>
            </div>
          </div>
        </div>
        <div className={styles.MainServices}>
          <h1>{t('pw_ph_services')}</h1>
          <div className={styles.IconBoxLine}>
            <div className={styles.IconBox2}>
              <img src={getStaticPath("/images/MobileLoadIcon.png")} alt="" />
              <p>{t('pw_ph_services_1')}</p>
            </div>
            <div className={styles.IconBox2}>
              <img src={getStaticPath("/images/MobileDataIcon.png")} alt="" />
              <p>{t('pw_ph_services_2')}</p>
            </div>
            <div className={styles.IconBox2}>
              <img src={getStaticPath("/images/paymentIcon.png")} alt="" />
              <p>{t('pw_ph_services_3')}</p>
            </div>
            <div className={styles.IconBox2}>
              <img src={getStaticPath("/images/gameIcon.png")} alt="" />
              <p>{t('pw_ph_services_4')}</p>
            </div>
          </div>
        </div>
        <div className={styles.MainRegisterStep}>
          <Button type={'primary'} className={styles.PartnerButton} onClick={() => { Router.push('/services').then(() => { window.scrollTo(0, 0) }) }}>
            {t('pw_ph_link_to_partner')}
          </Button>
          <h1>{t('pw_ph_register_step')}</h1>

          <div className={styles.SwapBox}>
            <div className={styles.SwapBoxLeft}>
              <div className={styles.SwapBoxLeftInner}>
                {swapList?.length ? (<Carousel
                  className={styles.SwapBoxLeftCarousel}
                  autoplay={swapAuto}
                  changeTime={5000}
                  list={swapList}
                  // dots={true}
                  onImgChange={(id: string) => {
                    setActiveSwap(id);
                  }}
                />) : (<></>)}
              </div>
            </div>
            <div className={styles.SwapBoxRight}>
              <div
                className={activeSwap === '1' ? styles.SwapActive : undefined}
                onClick={() => {
                  const node = document.getElementsByClassName('sg-carousel-carouselPager')
                  //@ts-ignore
                  node[0].children[0].click()
                  setActiveSwap('1')
                }}
              >
                <div className={styles.textLabel}>1</div>
                <div className={styles.textGroup}>
                  <p className={styles.textTitle}>{t('pw_ph_register_step_1')}</p>
                  <p className={styles.textDesc}>
                    {t('pw_ph_register_step_discription_1')}
                  </p>
                </div>
              </div>
              <div
                className={activeSwap === '2' ? styles.SwapActive : undefined}
                onClick={() => {
                  const node = document.getElementsByClassName('sg-carousel-carouselPager')
                  //@ts-ignore
                  node[0].children[1].click()
                  setActiveSwap('2')
                }}
              >
                <div className={styles.textLabel}>2</div>
                <div className={styles.textGroup}>
                  <p className={styles.textTitle}>{t('pw_ph_register_step_2')}</p>
                  <p className={styles.textDesc}>
                    {t('pw_ph_register_step_discription_2')}
                  </p>
                </div>
              </div>
              <div
                className={activeSwap === '3' ? styles.SwapActive : undefined}
                onClick={() => {
                  const node = document.getElementsByClassName('sg-carousel-carouselPager')
                  //@ts-ignore
                  node[0].children[2].click()
                  setActiveSwap('3')
                }}
              >
                <div className={styles.textLabel}>3</div>
                <div className={styles.textGroup}>
                  <p className={styles.textTitle}>{t('pw_ph_register_step_3')}</p>
                  <p className={styles.textDesc}>
                    {t('pw_ph_register_step_discription_3')}
                  </p>
                </div>
              </div>
              <div
                className={activeSwap === '4' ? styles.SwapActive : undefined}
                onClick={() => {
                  const node = document.getElementsByClassName('sg-carousel-carouselPager')
                  //@ts-ignore
                  node[0].children[3].click()
                  setActiveSwap('4')
                }}
              >
                <div className={styles.textLabel}>4</div>
                <div className={styles.textGroup}>
                  <p className={styles.textTitle}>{t('pw_ph_register_step_4')}</p>
                  <p className={styles.textDesc}>
                    {t('pw_ph_register_step_discription_4')}
                  </p>
                </div>
              </div>
            </div>
          </div>
        </div>
        <div className={styles.MainFAQs}>
          <Button type={'primary'} className={styles.PartnerButton}
            onClick={() => { Router.push('/faqs') }}>
            {t('pw_ph_link_to_faqs')}
          </Button>
        </div>
      </div>
    </div>
  );
}